import {SplittedChar, SplittedContainer} from './styled';

export const TextSplit = ({children}) => {
    const items = children.split('').map(c => (
        <SplittedChar key={c}>{c}</SplittedChar>
    ))

    return (
        <SplittedContainer>{items}</SplittedContainer>
    );
}