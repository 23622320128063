import { useNavigate, useParams } from "react-router-dom";
import { ExpandPanel } from "../../../components/ExpandPanel";
import { TopPanel } from "../../../components/TopPanel";
import { InnerLayout, Layout } from "../../../styled";
import { ProjectView } from "../../../components/ProjectView";

export const ProjectPage = () => {
    const { projectId } = useParams();
    const navigate = useNavigate();

    return (
        <Layout $topPanel>
            <TopPanel show />
            <ExpandPanel expand>
                <InnerLayout>
                <ProjectView
                show
                code={projectId}
                onCloseClick={() => navigate("/", { replace: true })}
            />
                </InnerLayout>
            </ExpandPanel>
        </Layout>
    )
}