import { FC, useEffect, useState } from "react";

interface ExpandPanelProps {
    expand: boolean;
    children: React.ReactNode;
    velocity?: number;
}

export const ExpandPanel: FC<ExpandPanelProps> = ({expand, children, velocity}) => {
    const [maxHeight, setMaxHeight] = useState(0);

    useEffect(() => {
      setMaxHeight(expand ? 1000 : 0);
    }, [expand])

    return (
      <div
        style={{
          /*backgroundColor: "#ddd",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",*/
          maxHeight,
          maxWidth: '100%',
          width:'100%',
          overflow: 'hidden',
          transitionDuration: `${velocity || 0.7}s`,
        }}
      >
        {children}
      </div>
    );
  };
