import styled from "styled-components";

interface IconContainerProps {
    $selected: boolean;
    $color?: string;
    $bgColor?: string;
}

export const IconContainer = styled.div<IconContainerProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.$bgColor || '#fff'};
  border-radius: 20px;
  min-width: 80px;
  min-height: 80px;
  margin-bottom: 5px;
  color: ${props => props.$color || '#000'};
  cursor: pointer;
  opacity: ${props => props.$selected ? 1 : 0.85};
  transform: ${props => props.$selected ? 'translateY(-5px)' : null};
  overflow: hidden;
  transition-duration: 0.2s;
  box-shadow: none;
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 10px 30px rgba(255,255,255,0.35);
    opacity: 1;
  }
`;

export const ProjectName = styled.div`
  position: absolute;
  top: 82px;
  color: #fff;
  font-size: 1.1rem;
  font-weight: 600;
  user-select: none;
  max-width: 120px;
  text-align: center;
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 110px;
  margin-right: 6px;
  margin-left: 6px;
  margin-bottom: 32px;
`;
