import React, { forwardRef, /*useEffect, */useState } from "react";
//import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import { IMaskInput } from "react-imask";
import Button from "@mui/material/Button";
import { ExpandPanel } from "../components/ExpandPanel";

/*
interface onChangeParams {
    target: {
        name: string;
        value: any;
    };
}

interface TextMaskCustomProps extends React.InputHTMLAttributes<HTMLInputElement> {
    inputRef: (ref: HTMLInputElement | null) => void;
    onChange: (params: onChangeParams) => void;
    name: string;
}

const TextMaskCustom = forwardRef(function TextMaskCustom(props: TextMaskCustomProps, ref) {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
            {...other}
            mask="+00000000000"
            definitions={{
              "#": /[1-9]/,
            }}
            inputRef={ref as any}
            onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
            overwrite
        />
    );
});
*/

export const RequestForm = ({ show, onCloseClick }) => {
    const [values, setValues] = useState({
        textmask: "",
    });

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value,
        });
    };

    return (
        <ExpandPanel expand={show} velocity={0.5}>
            <TextField
                required
                id="name"
                label="Имя"
                placeholder="Как к вам обращаться?"
                fullWidth
                sx={{ m: 1 }}
            />
            {/*<TextField
            required
            id="phone"
            label="Телефон"
            defaultValue="Введите номер телефона"
            type="tel"
          />
          <FormControl>
            <InputLabel htmlFor="phone-formatted" shrink>
              Телефон
            </InputLabel>
            <OutlinedInput
              value={values.textmask}
              onChange={handleChange}
              name="textmask"
              id="phone-formatted"
              inputComponent={TextMaskCustom}
            />
          </FormControl>*/}

            <TextField
                label="Телефон"
                value={values.textmask}
                onChange={handleChange}
                name="phone"
                id="phone"
               /* InputProps={{
                    inputComponent: TextMaskCustom,
                }}*/
                placeholder="+00000000000"
                variant="outlined"
                sx={{ m: 1 }}
            />
            <TextField
                required
                id="email"
                label="Email"
                placeholder="Введите email"
                type="email"
                sx={{ m: 1 }}
            />

            <TextField
                required
                id="description"
                label="Описание"
                multiline
                fullWidth
                minRows={5}
                placeholder="Кратко опишите суть проекта"
                type="text"
                sx={{ m: 1 }}
            />
            {/*<TextField
          disabled
          id="outlined-disabled"
          label="Disabled"
          defaultValue="Hello World"
        />
        <TextField
          id="outlined-password-input"
          label="Password"
          type="password"
          autoComplete="current-password"
        />
        <TextField
          id="outlined-read-only-input"
          label="Read Only"
          defaultValue="Hello World"
          InputProps={{
            readOnly: true,
          }}
        />
        <TextField
          id="outlined-number"
          label="Number"
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField id="outlined-search" label="Search field" type="search" />
        <TextField
          id="outlined-helperText"
          label="Helper text"
          defaultValue="Default Value"
          helperText="Some important text"
        />*/}
            <Button variant="contained">Отправить</Button>
            <Button variant="text" onClick={onCloseClick}>
                Назад
            </Button>
        </ExpandPanel>
    );
};
