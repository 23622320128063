import styled from "styled-components";
import fbIcon from './assets/fb.png';
import krasavaIcon from './assets/krasava.png';
import metallurgIcon from './assets/metallurgLogo.png';
import rubinIcon from './assets/rubinLogo.png';
import bg from './assets/bg.png';

interface LayoutProps {
    $topPanel: boolean;
}

export const Layout = styled.div<LayoutProps>`
  background-color: black;
  background-image: url(${bg});
  min-height: 100vh;
  //max-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  min-width: 300px;
  padding-top: ${props => props.$topPanel ? '40px' : 0};
  transition-duration: 0.5s;
`;

export const InnerLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
  box-sizing: border-box;
`;

export const Header = styled.div`
  color: yellow;
  font-size: calc(70px + 2vmin);
  font-weight: 600;
  cursor: grab;
  user-select: none;
`;

export const SubHeader = styled.div`
  color: gray;
  font-size: calc(10px + 2vmin);
  font-weight: 500;
  user-select: none;
  margin-bottom: 24px;
  text-align: right;
`;

export const InnerTitle = styled.div`
  font-weight: 600;
  user-select: none;
  margin-bottom: 16px;
`;

export const ProjectIconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 10px;
  min-height: 130px;
  max-width: 800px;
`;

export const FinanceBroIcon = styled.div`
  background-image: url(${fbIcon});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 100%;
  min-height: 80px;
`;

export const KrasavaIcon = styled.div`
  background-image: url(${krasavaIcon});
  background-position: center center;
  background-repeat: no-repeat;
  background-color: yellow;
  background-size: contain;
  width: 100%;
  height: 100%;
  min-height: 80px;
`;

export const MetallurgIcon = styled.div`
  background-image: url(${metallurgIcon});
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #d10000;
  background-size: contain;
  width: 100%;
  height: 100%;
  min-height: 80px;
`;

export const RubinIcon = styled.div`
  background-image: url(${rubinIcon});
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #FFBE00;
  background-size: contain;
  width: 100%;
  height: 100%;
  min-height: 80px;
`;
