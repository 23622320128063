import { Container, Title, SubTitle, Inner, LinkIcon, RightSide } from "./styled";
import TelegramIcon from '@mui/icons-material/Telegram';
import EmailIcon from '@mui/icons-material/Email';
import { EMAIL_LINK, TELEGRAM_LINK } from "../../configuration";

export const TopPanel = ({ show }) => {
    return (
        <Container $shadow={show}>
            <Inner $visible={show}>
                <Title onClick={() => window.location.reload()}>striped</Title>
                <SubTitle>Create something new</SubTitle>
            </Inner>
            <RightSide>
                <LinkIcon onClick={() => window.open(TELEGRAM_LINK, '_blank')} title="Telegram me">
                    <TelegramIcon fontSize="large"/>
                </LinkIcon>
                <LinkIcon onClick={() => window.open(EMAIL_LINK)} title="Mail me">
                    <EmailIcon fontSize="large"/>
                </LinkIcon>
            </RightSide>
        </Container>
    );
};
