import { useRef, useState, FC } from "react";
import { LOCALES, useLocale } from ".";
import { Container, Content } from "./styled";
import { useOutsideAlerter } from "../hooks";
import { FlagOption } from "./FlagOption";


interface FlagOptionProps {
    onSelect: (locale: string) => void;
}

export const FlagLocaleSelector:FC<FlagOptionProps> = ({ onSelect }) => {
    const [opened, setOpened] = useState(false);
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, () => setOpened(false));
    const { /*L, */currentLocale } = useLocale();
    //console.log("currentLocale: ", currentLocale);
    const options = Object.keys(LOCALES).map((l) => {
        if (l === currentLocale) {
            return null;
        }
        return (
            /*<option value={l} key={l}>
              {LOCALES[l]}
            </option>*/
            <FlagOption
                key={l}
                locale={l}
                //selected={l === currentLocale}
                onClick={() => {
                    setOpened(false);
                    onSelect(l);
                }}
            />
        );
    });
    return (
        <Container>
            <Content
                ref={wrapperRef}
                $opened={opened}
                $optionsCount={options.length + 1}
            //onMouseEnter={() => setOpened(true)}
            //onMouseLeave={() => setOpened(false)}
            >
                <FlagOption
                    selected
                    locale={currentLocale}
                    onClick={() => setOpened(!opened)}
                />
                {options}
                {/*opened && <div>{options}</div>*/}
            </Content>
        </Container>
    );
};
