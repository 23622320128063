import { FC } from "react";
import { PROJECT_ICON } from ".";
import { ProjectIcon } from "../../components/ProjectIcon";
import { PROJECT_DATA } from "../../configuration";
import { useLocale } from "../../localization";
import { IconsGroup } from "./IconsGroup";

const PROJECT_ENABLED = {
    fb: true,
    krasava: true,
    metallurg: true,
    rubin: false,
    orderProject: false,
} as const;


interface ProjectsProps {
    selected?: string;
    onClick?: (key: string) => void;
}

export const Projects: FC<ProjectsProps> = ({ selected, onClick }) => {
    const { getByLocale, L } = useLocale();

    const projects = Object.keys(PROJECT_ENABLED).map((key: string, index: number) => {
        if (!PROJECT_ENABLED[key]) {
            return null;
        }

        const project = PROJECT_DATA[key];
        const icon = PROJECT_ICON[key];

        if (!project || !icon) {
            return null;
        }

        const name = getByLocale(project.name);

        return (
            <ProjectIcon
                key={key}
                name={name}
                onClick={() => onClick(key)}
                selected={selected === key}
            >
                {icon}
            </ProjectIcon>
        );
    })

    return (
        <IconsGroup title={L("_projectsSectionTitle")}>
            {projects}
        </IconsGroup>
    )
};