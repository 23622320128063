export enum MobileOS {
    ANDROID = "android",
    IOS = "ios",
    WINDOWS_PHONE = "windows phone",
    UNKNOWN = "unknown",
  };

  /**
   * Determine the mobile operating system.
   * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
   *
   * @returns {String}
   */
  export function getMobileOperatingSystem() {
    const wnd = window as any;
    var userAgent = navigator.userAgent || navigator.vendor || wnd.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return MobileOS.WINDOWS_PHONE;
    }

    if (/android/i.test(userAgent)) {
      return MobileOS.ANDROID;
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !wnd.MSStream) {
      return MobileOS.IOS;
    }

    return MobileOS.UNKNOWN;
  }

  export const isMobile = () => {
    const OS = getMobileOperatingSystem();
    return (OS === MobileOS.IOS || OS === MobileOS.ANDROID);
  }

  export const getQueryParam = (paramName) => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(paramName);
  };
