import { createContext, useContext } from "react";

export const LOCALES = {
    ru: 'Русский',
    en: 'English',
    es: 'Español',
    sr: 'Српски',
}

export const DEFAULT_LOCALE = 'en';

export const setUserLocale = (locale) => {
    localStorage.locale = locale;
}

export const getInitialLocale = () => {
    let lang = localStorage.locale;
    if (!lang || !LOCALES[lang]) {
        lang = navigator?.language?.split('-')?.[0];
    }

    if (!lang || !LOCALES[lang]) {
        lang = DEFAULT_LOCALE;
    }

    return lang;
}

export const LocaleContext = createContext(getInitialLocale());

const languages = {
    _russian: {
        ru: 'Русский',
        en: 'Russian',
        es: 'Ruso',
        sr: 'Руски',
        de: 'Russisch',
    },
    _english: {
        ru: 'Английский',
        en: 'English',
        es: 'Inglés',
        sr: 'Енглески',
        de: 'Englisch',
    },
    _spanish: {
        ru: 'Испанский',
        en: 'Spanish',
        es: 'Español',
        sr: 'Шпански',
        de: 'Spanisch',
    },
    _serbian: {
        ru: 'Сербский',
        en: 'Serbian',
        es: 'Serbio',
        sr: 'Српски',
        de: 'Serbisch',
    },
    _portuguese: {
        ru: 'Португальский',
        en: 'Portuguese',
        es: 'Portugués',
        sr: 'Португалски',
        de: 'Portugiesisch',
    },
    _french: {
        ru: 'Французский',
        en: 'French',
        es: 'Francés',
        sr: 'Француски',
        de: 'Französisch',
    },
    _german: {
        ru: 'Немецкий',
        en: 'German',
        es: 'Alemán',
        sr: 'Немачки',
        de: 'Deutsch',
    },
}

const cvStrings = {
    _cvSummary: {
        ru: 'Обо мне',
        en: 'About me',
        es: 'Sobre mí',
        sr: 'О мени',
    },
    _cvEducation: {
        ru: 'Образование',
        en: 'Education',
        es: 'Educación',
        sr: 'Образовање',
    },
    _cvExperience: {
        ru: 'Опыт работы',
        en: 'Experience',
        es: 'Experiencia',
        sr: 'Искуство',
    },
    _cvSkills: {
        ru: 'Навыки',
        en: 'Skills',
        es: 'Habilidades',
        sr: 'Вештине',
    },
    _cvTools: {
        ru: 'Инструменты',
        en: 'Tools',
        es: 'Herramientas',
        sr: 'Алати',
    },
    _cvLanguages: {
        ru: 'Языки',
        en: 'Languages',
        es: 'Idiomas',
        sr: 'Језици',
    },
    _cvContacts: {
        ru: 'Контакты',
        en: 'Contacts',
        es: 'Contactos',
        sr: 'Контакти',
    },
    _cvInterests: {
        ru: 'Интересы',
        en: 'Interests',
        es: 'Intereses',
        sr: 'Интереси',
    },
    _cvCertificates: {
        ru: 'Сертификаты',
        en: 'Certificates',
        es: 'Certificados',
        sr: 'Сертификати',
    },
}

const strings = {
    _languageTitle: {
        ru: 'Язык',
        en: 'Language',
        es: 'Idioma',
        sr: 'Језик',
    },
    _closeButton: {
        ru: 'закрыть',
        en: 'close',
        es: 'cerrar',
        sr: 'затвори',
    },
    _goButton: {
        ru: 'перейти',
        en: 'open',
        es: 'abrir',
        sr: 'отвори',
    },
    _projectsSectionTitle: {
        ru: 'Проекты',
        en: 'Projects',
        es: 'Proyectos',
        sr: 'Пројекти',
    },
    _orderProjectTitle: {
        ru: 'Заказать новый',
        en: 'Order a new one',
        es: 'Ordenar uno nuevo',
        sr: 'Наручите нови',
    },
    ...cvStrings,
    ...languages,
}

export const getByLocale = (locale: string, values: Record<string, string>) => {
    return values?.[locale] || values?.[DEFAULT_LOCALE] || `{unknown_${locale}}`;
}

export const L = (locale: string, key: string) => {
    return strings[key]?.[locale] || strings[key]?.[DEFAULT_LOCALE] || `{${key}}`;
}

export const useLocale = () => {
    const locale = useContext(LocaleContext);

    return {
        L: (key) => L(locale, key),
        getByLocale: (values) => getByLocale(locale, values),
        currentLocale: locale,
    }
}