import { useState, useEffect, FC } from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import { yellow } from "@mui/material/colors";
import Skeleton from "@mui/material/Skeleton";
import { ExpandPanel } from "../ExpandPanel";
import {
  Icon,
  Top,
  Texts,
  Container,
  Title,
  Description,
  ScreenshotsContainer,
  Screenshot,
  ScreenshotsList,
  ButtonsContainer,
} from "./styled";
import { isMobile } from "../../utils";
import { useLocale } from "../../localization";
import { PROJECT_DATA } from "../../configuration";
import { useParams } from "react-router";

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(yellow[400]),
  backgroundColor: yellow[400],
  "&:hover": {
    backgroundColor: yellow[600],
  },
}));

const ColorTextButton = styled(Button)(({ theme }) => ({
  color: yellow[400],
  "&:click": {
    backgroundColor: yellow[600],
  },
}));

interface SkeletonWrapperProps {
    loading: boolean;
    children: React.ReactNode;
}

const SkeletonWrapper: FC<SkeletonWrapperProps> = ({ loading, children }) => {
  if (!loading) {
    return children;
  }

  return (
    <>
      <Top>
        <Skeleton
          variant="rectangular"
          width={130}
          height={130}
          sx={{ bgcolor: "grey.900" }}
        />
        <Texts>
          <Skeleton variant="text" sx={{ bgcolor: "grey.900" }} width={200} />
          <Skeleton variant="text" sx={{ bgcolor: "grey.900" }} width={200} />
          <Skeleton variant="text" sx={{ bgcolor: "grey.900" }} width={200} />
        </Texts>
      </Top>
      <ScreenshotsContainer>
        <ScreenshotsList>
          <Skeleton
            variant="rectangular"
            width={200}
            height={500}
            sx={{ bgcolor: "grey.900" }}
          />
          <div style={{ minWidth: "16px", height: "100px" }} />
          <Skeleton
            variant="rectangular"
            width={200}
            height={500}
            sx={{ bgcolor: "grey.900" }}
          />
          <div style={{ minWidth: "16px", height: "100px" }} />
          <Skeleton
            variant="rectangular"
            width={200}
            height={500}
            sx={{ bgcolor: "grey.900" }}
          />
          <div style={{ minWidth: "16px", height: "100px" }} />
        </ScreenshotsList>
      </ScreenshotsContainer>
    </>
  );
};


interface ProjectViewProps {
    show: boolean;
    code: string;
    onCloseClick: () => void;
}

export const ProjectView: FC<ProjectViewProps> = ({ show, code, onCloseClick }) => {
  const [innerShow, setInnerShow] = useState(show);
  const [projectCode, setProjectCode] = useState(code);
  useEffect(() => {
    //console.log("projectCode", projectCode);
    //console.log("code", code);
    if (!code) {
      setTimeout(() => {
        setProjectCode(code);
      }, 500);
      return;
    }
    setProjectCode(code);
    setInnerShow(false);
    setTimeout(() => {
      setInnerShow(true);
      setProjectCode(code);
    }, 500);
  }, [code]);

  useEffect(() => {
    setInnerShow(show);
  }, [show]);

  const { L, getByLocale } = useLocale();

  const params = useParams();

  console.log("params", params);

  const projectData = PROJECT_DATA[projectCode];

  const screenshots = projectData?.screenshots || [];
  let targetUrl = projectData?.url || "";
  if (isMobile() && projectData?.deepLink) {
    targetUrl = projectData.deepLink;
  }
  const title = getByLocale(projectData?.title);
  const description = getByLocale(projectData?.description);

  return (
    <ExpandPanel expand={innerShow} velocity={0.5}>
      <Container>
        <SkeletonWrapper loading={!projectCode}>
          <Top>
            <Icon src={projectData?.icon} />
            <Texts>
              <Title>{title}</Title>
              <Description>{description}</Description>
              <ButtonsContainer>
                <Stack spacing={2} direction="row">
                  <ColorTextButton
                    variant="text"
                    color="error"
                    onClick={onCloseClick}
                  >
                    {L("_closeButton")}
                  </ColorTextButton>
                  {targetUrl && (
                    <ColorButton
                      variant="contained"
                      onClick={() => window.open(targetUrl, "_blank")}
                    >
                      {L("_goButton")}
                    </ColorButton>
                  )}
                </Stack>
              </ButtonsContainer>
            </Texts>
          </Top>
          <ScreenshotsContainer>
            <ScreenshotsList>
              {projectData?.video && (
                  <div style={{transform: 'translateZ(0px)', borderRadius: '20px'}}>
                <iframe
                style={{borderRadius: '20px'}}
                  width="250"
                  height="540"
                  src={projectData.video}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen={true}
                ></iframe>
                </div>
              )}
              {screenshots.map((s) => {
                return <Screenshot src={s} key={s} />;
              })}
              <div style={{ minWidth: "16px", height: "100px" }} />
            </ScreenshotsList>
          </ScreenshotsContainer>
        </SkeletonWrapper>
      </Container>
    </ExpandPanel>
  );
};
