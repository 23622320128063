import { FC } from "react";
import { Flag } from "./styled";

interface FlagOptionProps {
    locale: string;
    onClick: () => void;
    selected?: boolean;
}

export const FlagOption: FC<FlagOptionProps> = ({ locale, onClick, selected }) => {
    return <Flag $locale={locale} onClick={onClick} $selected={selected} />;
};
