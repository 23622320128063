import { useEffect, useState } from "react";
import {
    Layout,
    Header,
    SubHeader,
    FinanceBroIcon,
    KrasavaIcon,
    InnerLayout,
    MetallurgIcon,
    RubinIcon,
} from "../../styled";
import { TextSplit } from "../../components/TextSplit";
import { RequestForm } from "../../RequestForm";
import AddIcon from "@mui/icons-material/Add";
import { ProjectView } from "../../components/ProjectView";
import { ProjectIcon } from "../../components/ProjectIcon";
import { ExpandPanel } from "../../components/ExpandPanel";
import { TopPanel } from "../../components/TopPanel";
import { PROJECT_DATA } from "../../configuration";
import { useLocale } from "../../localization";
import { Projects } from "./Projects";
import { ToggleSelector } from "../../ui-kit/ToggleSelector";
import { useNavigate } from "react-router-dom";

const PROJECT_ENABLED = {
    fb: true,
    krasava: true,
    metallurg: false,
    rubin: true,
    orderProject: false,
} as const;


export const PROJECT_ICON = {
    fb: <FinanceBroIcon />,
    krasava: <KrasavaIcon />,
    metallurg: <MetallurgIcon />,
    rubin: <RubinIcon />,
    orderProject: <AddIcon fontSize="large" />,
} as const;

export const MainPage = () => {
    const [selectedProject, setSelectedProject] = useState<string>();

    const navigate = useNavigate();

    const navegateToProject = (key: string) => {
        navigate(`/projects/project/${key}`);
    }

    const { getByLocale, L } = useLocale();

    useEffect(() => {
        // PROJECT_DATA.krasava.screenshots.forEach(s => {
        //     fetch(s);
        // });
    }, []);

    const topPanel = !!selectedProject;

    return (
        <Layout $topPanel={topPanel}>
            <TopPanel show={topPanel} />
            <ExpandPanel expand={!topPanel} velocity={0.5}>
                <InnerLayout>
                    <Header>
                        <TextSplit>striped</TextSplit>
                    </Header>
                    <SubHeader>Create something new</SubHeader>
                    <Projects selected={selectedProject} onClick={navegateToProject} />
                </InnerLayout>
            </ExpandPanel>
            <ProjectView
                show={!!selectedProject}
                code={selectedProject}
                onCloseClick={() => setSelectedProject(null)}
            />
            <RequestForm show={selectedProject === "orderProject"} onCloseClick={() => setSelectedProject(null)} />
        </Layout>
    );
};
