import krasavaIcon from "./assets/krasava.png";
import metallurgIcon from "./assets/metallurgLogo.png";
import rubinIcon from "./assets/rubinLogo.png";

export const PROJECT_DATA = {
    krasava: {
        name: {
            ru: `ФК "Красава"`,
            en: `FC "Krasava"`,
            es: `CF "Krasava"`,
            sr: `ФК "Красава"`,
        },
        title: {
            ru: `Футбольный клуб "Красава"`,
            en: `Football club "Krasava"`,
            es: `Club de fútbol "Krasava"`,
            sr: `Фудбалски клуб "Красава"`,
        },
        screenshots: [
            //"/screenshots/krasava/screenshot_00.png",
            "/screenshots/krasava/screenshot_01.png",
            "/screenshots/krasava/screenshot_08.png",
            "/screenshots/krasava/screenshot_10.png",
            "/screenshots/krasava/screenshot_02.png",
            "/screenshots/krasava/screenshot_03.png",
            "/screenshots/krasava/screenshot_09.png",
            "/screenshots/krasava/screenshot_06.png",
            "/screenshots/krasava/screenshot_07.png",
            "/screenshots/krasava/screenshot_04.png",
        ],
        video: "https://www.youtube.com/embed/xng3iSrR8cM",
        description: {
            ru: "Приложение футбольного клуба на платформах iOS и Android. Быстрая реализация в виде обёртки для адаптивного сайта клуба с использованием JS-инъекций и добавлением нативного функционала",
            en: "Football club app for iOS and Android platforms. Fast implementation as a wrapper for a responsive club site using JS injections and adding native functionality",
            es: "Aplicación de club de fútbol para plataformas iOS y Android. Implementación rápida como envoltorio para un sitio de club receptivo usando inyecciones JS y agregando funcionalidad nativa",
            sr: "Апликација фудбалског клуба на iOS и Android платформама. Брза примена у облику омота за одзивну клупску локацију помоћу JS ињекција и додавањем изворне функционалности",
        },
        //url: require('../assets/krasava.png'),
        icon: krasavaIcon,
    },
    fb: {
        name: {
            ru: "finance bro",
            en: "finance bro",
            es: "finance bro",
            sr: "finance bro",
        },
        title: {
            ru: "finance bro - учёт финансов",
            en: "finance bro - your finance",
            es: "finance bro - tus finanzas",
            sr: "finance bro - ваше финансије",
        },
        description: {
            ru: "Приложение для учёта и анализа личных финансов на платформах iOS и Android",
            en: "Application for accounting and analysis of personal finances on iOS and Android platforms",
            es: "Aplicación para contabilidad y análisis de finanzas personales en plataformas iOS y Android",
            sr: "Апликација за рачуноводство и анализу личних финансија на iOS и Android платформама",
        },
        screenshots: [
            "https://is5-ssl.mzstatic.com/image/thumb/PurpleSource124/v4/33/59/66/335966b1-a0fc-0672-fb2e-ea46f95bc33f/67849a7c-8fd8-4d07-9ddb-044a20726cca__U0422_U0435_U043b_U0435_U0444_U043e_U043d__U0441_U043d_U0438_U0437_U0443_65.png/460x0w.png",
            "https://is3-ssl.mzstatic.com/image/thumb/PurpleSource114/v4/ae/e4/c9/aee4c970-df63-46df-d55c-3291b398e35d/2f0c29d9-ca32-4857-bbad-7db26f5e4b47__U0422_U0435_U043b_U0435_U0444_U043e_U043d__U0441_U0432_U0435_U0440_U0445_U0443_65.png/460x0w.png",
            "https://is5-ssl.mzstatic.com/image/thumb/PurpleSource114/v4/73/51/e0/7351e05f-f0d3-8f54-3474-3f38f2ced270/b82e8ab7-d872-42b1-b42e-16b208aeb4c8__U0422_U0435_U043b_U0435_U0444_U043e_U043d__U043f_U043e_U043b_U043d_U043e_U0441_U0442_U044c_U044e_65-1.png/460x0w.png",
            "https://is5-ssl.mzstatic.com/image/thumb/PurpleSource114/v4/91/fb/30/91fb30cb-fd9e-4006-37a8-1b7c420f6ea3/459e6355-2a7d-4d4a-87a8-99494a6c3a5b__U0422_U0435_U043b_U0435_U0444_U043e_U043d__U0441_U043d_U0438_U0437_U0443_65-1.png/460x0w.png",
            "https://is5-ssl.mzstatic.com/image/thumb/Purple114/v4/d9/80/13/d98013a7-e265-e5fb-2e66-b739242c1cfd/tns.hnjjbpho.png/460x0w.png",
        ],
        //url: "https://finbro.club/",
        //deepLink: "https://app.finbro.club/",
        icon: "https://is3-ssl.mzstatic.com/image/thumb/Purple114/v4/e0/6c/6b/e06c6b66-d05b-707d-565e-a20e648c6ccc/AppIcon-0-0-1x_U007emarketing-0-0-0-5-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.png/460x0w.png",
    },
    metallurg: {
        name: {
            ru: `ФК "Металлург"`,
            en: `FC "Metallurg"`,
            es: `CF "Metallurg"`,
            sr: `ФК "Металлург"`,
        },
        title: {
            ru: `Футбольный клуб "Металлург" Липецк`,
            en: `Football club "Metallurg" Lipetsk`,
            es: `Club de fútbol "Metallurg" Lipetsk`,
        },
        description: {
            ru: "Универсальное PWA приложение для футбольного клуба, на примере ФК Металлург (Липецк), которое быстро может быть адаптировано под любой другой клуб (или даже недоклуб :)) или изменение дизайна/лиги и т.д.",
            en: "Universal PWA application for a football club, using FC Metallurg (Lipetsk) as an example, which can quickly be adapted for any other club or design change/league etc.",
            es: "Aplicación PWA universal para un club de fútbol, utilizando el FC Metallurg (Lipetsk) como ejemplo, que puede adaptarse rápidamente a cualquier otro club o cambio de diseño/liga, etc.",
            sr: "Универзална PWA апликација за фудбалски клуб, користећи ФК Металург (Липецк) као пример, која се брзо може прилагодити за било који други клуб или промену дизајна/лиге итд.",
        },
        screenshots: [
            "/screenshots/fcml/screenshot_00001.png",
            "/screenshots/fcml/screenshot_00003.png",
            "/screenshots/fcml/screenshot_00002.png",
            "/screenshots/pfcsokol/screenshot_00.png",
            "/screenshots/pfcsokol/screenshot_01.png",
            "/screenshots/pfcsokol/screenshot_03.png",
            "/screenshots/pfcsokol/screenshot_04.png",
            "/screenshots/pfcsokol/screenshot_05.png",
        ],
        url: "https://dev.striped.online/fcml",
        deepLink: "",
        icon: metallurgIcon,
    },
};

export const TELEGRAM_LINK = "https://t.me/gikkert";

export const EMAIL_LINK = "mailto:mail@striped.online";
