import { FC, useState } from "react";
import { Container, IconContainer, ProjectName } from "./styled";

interface ProjectIconProps {
    name: string;
    children?: React.ReactNode;
    onClick: () => void;
    selected: boolean;
    color?: string;
    bgColor?: string;
}

export const ProjectIcon: FC<ProjectIconProps> = ({ name, children, onClick, selected, color, bgColor }) => {
  const [nameVisible, setNameVisible] = useState(false);
  return (
    <Container onClick={onClick}>
      <IconContainer
        onMouseEnter={() => setNameVisible(true)}
        onMouseLeave={() => setNameVisible(false)}
        $selected={!!selected}
        $color={color}
        $bgColor={bgColor}
      >
        {children}
      </IconContainer>
      {(selected || nameVisible) && <ProjectName>{name}</ProjectName>}
    </Container>
  );
};
