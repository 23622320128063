import { FC, ReactNode } from "react"
import { InnerTitle, ProjectIconsContainer } from "../../styled"

interface IconsGroupProps {
    title?: string,
    children?: ReactNode,
}
export const IconsGroup: FC<IconsGroupProps> = ({ title, children }) => {
    return (
        <>
            <InnerTitle>{title}</InnerTitle>
            <ProjectIconsContainer>
                {children}
            </ProjectIconsContainer>
        </>
    )
}