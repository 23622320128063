import styled from "styled-components";

interface ContainerProps {
  $shadow: boolean;
}

export const Container = styled.div<ContainerProps>`
  box-shadow: 0px ${(props) => (props.$shadow ? '1px 10px' : '0 0')} #333;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  background-color: rgba(0, 0, 0, ${(props) => (props.$shadow ? 1 : 0)});
  transition-duration: 0.5s;
  z-index: 999;
  animation: landing-container-fade-in 1s ease-in-out;
`;

interface InnerProps {
  $visible: boolean;
}

export const Inner = styled.div<InnerProps>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  max-width: 60%;
  padding-left: 10px;
  transition-duration: 0.5s;
  opacity: ${(props) => (props.$visible ? 1 : 0)};
`;

export const Title = styled.div`
  color: yellow;
  font-size: 20px;
  font-weight: 600;
  margin-right: 8px;
  cursor: pointer;
`;

export const SubTitle = styled.div`
  color: #bbb;
  font-size: 16px;
  font-weight: 400;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const LinkIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8px;
  cursor: pointer;
  opacity: 0.8;
  &:hover {
    //transform: translateY(-5px);
    color: yellow;
    opacity: 1;
  }
  @media (max-width: 600px) {
    transform: scale(0.8);
    margin: 4px;
  }
`;

export const RightSide = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-right: 64px;
`;