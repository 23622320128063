import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: gray;
  max-width: 100%;
  margin-top: 36px;
  background-color: rgba(0,0,0,0.4);
`;

export const Icon = styled.div<{ src: string; }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 20px;
  min-width: 130px;
  min-height: 130px;
  width: 130px;
  height: 130px;
  margin-bottom: 12px;
  margin-top: 8px;
  cursor: pointer;
  overflow: hidden;
  background-image: ${props => props.src ? `url(${props.src})` : 'none'};
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  @media (min-width: 450px) {
    margin-bottom: 0;
  }
`;

export const Top = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
  @media (min-width: 450px) {
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 24px;
  }
`;


export const Texts = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 12px;

  @media (min-width: 450px) {
    max-width: 500px;
    align-items: flex-start;
    margin-left: 16px;
    padding: 6px 0;
  }
`;

export const Title = styled.div`
  color: #fff;
  font-weight: 600;
  margin-bottom: 8px;
`;

export const Description = styled.div`
  color: #fff;
  font-weight: 400;
  opacity: 0.85;
  font-size: 1rem;
  text-align: center;
  margin-bottom: 16px;
  @media (min-width: 450px) {
    text-align: left;
  }
`;

export const ScreenshotsContainer = styled.div`
  max-width: 100%;
  overflow-x: auto;
`;

export const ScreenshotsList = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 16px;
`;

export const Screenshot = styled.img`
  width: 250px;
  margin: 0 6px;
  border-radius: 20px;
`;

export const ButtonsContainer = styled.div`
  align-self: center;
  @media (min-width: 450px) {
    align-self: flex-end;
  }
`;
