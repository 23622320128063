import { useState } from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
    HashRouter,
} from "react-router-dom";
import { getInitialLocale, LocaleContext, setUserLocale } from "./localization";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { FlagLocaleSelector } from "./localization/FlagLocaleSelector";
import { MainPage } from "./pages/main";
import { NavigateSetter } from "./components/NavigateSetter";
import { CvPage } from "./pages/cv";
import { ProjectPage } from "./pages/projects/project";

const darkTheme = createTheme({
    palette: {
        mode: "dark",
    },
});

const App = () => {
    const [locale, setLocale] = useState(getInitialLocale());

    return (
        <ThemeProvider theme={darkTheme}>
            <LocaleContext.Provider value={locale}>
                {/*<div style={{ position: "fixed", right: 0, top: 0, zIndex: 1000 }}>*/}
                    <FlagLocaleSelector onSelect={(v: string) => {
                        setLocale(v);
                        setUserLocale(v)
                    }} />
                {/*</div>*/}

                <HashRouter>
                    <NavigateSetter />
                    <Routes>
                        {/*<Route path="/projects/:projectCode" element={<MainPage />} />*/}
                        <Route path="/" element={<MainPage />} />
                        {/*<Route path="/cv" element={<CvPage />} />*/}
                        <Route path="/projects/project/:projectId" element={<ProjectPage />} />
                        <Route path="*" element={<Navigate to="/" replace />} />
                    </Routes>
                </HashRouter >
            </LocaleContext.Provider>
        </ThemeProvider>
    );
};

export default App;
