import styled from "styled-components";

import esFlag from "../assets/flags/es.png";
import ruFlag from "../assets/flags/ru.png";
import enFlag from "../assets/flags/en.png";
import srFlag from "../assets/flags/sr.png";

const FLAGS = {
  es: esFlag,
  ru: ruFlag,
  en: enFlag,
  sr: srFlag,
};

export const Container = styled.div`
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1000;
`

interface ContentProps {
    $opened: boolean;
    $optionsCount: number;
}

export const Content = styled.div<ContentProps>`
  padding-top: 10px;
  overflow: hidden;
  //background-color: rgba(0, 0, 0, ${(props) => (props.$opened ? 1 : 0)});
  background-color: #000;
  width: 60px;
  max-height: ${(props) => (props.$opened ? props.$optionsCount * 50 : 50)}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border-radius: 0 0 30px 30px;
  transition-duration: 0.5s;
  //border-radius: 30px;
  box-shadow: ${(props) => (!props.$opened ? 'none' : '0px 0px 20px rgba(0, 0, 0,1)')};
`;

interface FlagProps {
    $locale: string;
    $selected: boolean;
}

export const Flag = styled.div<FlagProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 20px;
  width: 40px;
  height: 40px;
  min-height: 40px;
  margin-bottom: 12px;
  cursor: pointer;
  background-image: ${(props) =>
    props.$locale ? `url(${FLAGS[props.$locale]})` : "none"};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  text-transform: uppercase;
  box-sizing: border-box;
  border: 1px solid ${(props) => (props.$selected ? "#fff" : "#555")};
  opacity: ${(props) => (props.$selected ? 0.9 : 0.75)};
  transition-duration: 0.2s;
  box-shadow: none;
  &:hover {
    box-shadow: 0px 0px 30px rgba(255, 255, 255, 0.35);
    opacity: 1;
  }
`;
