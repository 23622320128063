import styled from "styled-components";

export const SplittedContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const SplittedChar = styled.div`
  opacity: 0.8;
  transition-duration: 0.3s;
  &:hover {
    transform: translateY(-2px) scale(1.05);
    opacity: 1;
  }
`;
